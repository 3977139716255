import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import {
  Alert,
  Anchor,
  AppShell,
  Box,
  Button,
  Checkbox,
  SegmentedControl,
  Stack,
  Text,
  ThemeIcon,
  Title,
  useMatches,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { motion } from 'framer-motion';
import { FcGoogle } from 'react-icons/fc';
import {
  PiCaretLeft,
  PiCaretRight,
  PiChecks,
  PiCity,
  PiEnvelopeSimple,
  PiUser,
  PiWarning,
  PiXCircle,
} from 'react-icons/pi';
import { VscGithubInverted } from 'react-icons/vsc';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import isEmail from 'validator/es/lib/isEmail';

import { useAuthModes } from '../../../hooks';
import { authEndpoints } from '../../../utils/constants';
import { paths, UnProtectedRoute } from '../../../utils/routeUtils';
import { getRedir } from '../utils';
import {
  AnimateInAndOut,
  AuthButton,
  isPasswordValid,
  LoginTopBar,
  LoginWidgetContainer,
  OrDivider,
  PasswordInput,
  StyledTextInput,
} from './components';
import {
  ICON_SIZE,
  LINKS,
  PageTitle,
  ShouldShowSubmitButton,
  SubmitButtonTitle,
} from './constants';
import { useLoadingState } from './hooks/useLoadingState';
import { useRedirectUrlPersistence } from './hooks/useRedirectUrlPersistence';
import { useTypedNavigate } from './hooks/useTypedNavigate';
import MantineProvider from './MantineProvider/MantineProvider';
import { notifications } from './notifications';
import { LandingPageProps } from './types';
import { getRouteType } from './utils/getRouteType';
import { isSSOError } from './utils/isSSOError';
import { oauthUrl } from './utils/oauth-url';
import { RegisterData, registerNewUser } from './utils/register-new-user';
import { resetPassword } from './utils/reset-password';
import { signInWithEmail } from './utils/sign-in-with-email';
import { signInWithSSO } from './utils/sign-in-with-sso';

type NewUserForm = Omit<RegisterData, 'email' | 'password'> & {
  agreeToTermsAndConditions: boolean;
};

const unReslovedPromise = new Promise<void>(resolve => {
  setTimeout(() => {
    // resolving it after 5 seconds
    // sometimes state is retained on back button press
    resolve();
  }, 6000);
});

type LoadingIds =
  | 'sso'
  | 'email'
  | 'reset-password'
  | 'new-user'
  | 'google'
  | 'github';

const NewLoginPageUI = ({ loginCallback }: LandingPageProps) => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  const [loadingTask, { isLoading, isIdLoading }] =
    useLoadingState<LoadingIds>();

  const typedPath = getRouteType(pathname);

  const isNewUserOrEmail =
    typedPath === '/signup/new_user' || typedPath === '/signup/email';

  const typedNavigate = useTypedNavigate();

  const ssoError = useMemo(() => isSSOError(search), [search]);

  const { allowedAuthModes } = useAuthModes();

  const validPathName = (() => {
    if (typedPath === '/signup/new_user' || typedPath === '/signup/forgot')
      return typedPath;

    if (!allowedAuthModes.length) return null;

    if (allowedAuthModes.find(a => a.route === pathname)) return typedPath;

    const firstValidPathname = allowedAuthModes[0].route;

    return firstValidPathname;
  })();

  const authModes = useAuthModes();

  // OAuth2 failures can remove redirect_url from the search params,
  // the following hook makes sure its persisted for 5 minutes and replace when redirect_url is removed with OAuth2 error
  useRedirectUrlPersistence();

  const { isAuthModeSuppported, isSamlAuthSupported } = authModes;

  const { githubOAuthUrl, googleOAuthUrl } = authEndpoints;

  const emailForm = useForm({
    mode: 'controlled',
    initialValues: {
      email: '',
    },
    validate: {
      email: value => (isEmail(value) ? null : 'Invalid email'),
    },
  });

  const passwordForm = useForm({
    mode: 'controlled',
    initialValues: {
      password: '',
    },
    validate: {
      password: value => {
        if (typedPath === '/signup/new_user') {
          return isPasswordValid(value)
            ? null
            : 'Password does not meet requirements';
        } else {
          return value.trim() ? null : 'Password is required';
        }
      },
    },
  });

  const newUserForm = useForm<NewUserForm>({
    mode: 'controlled',
    initialValues: {
      confirmPassword: '',
      organization: '',
      firstName: '',
      lastName: '',
      agreeToTermsAndConditions: false,
    },
    validate: {
      confirmPassword: value => {
        return value === passwordForm.getValues().password
          ? null
          : 'Passwords do not match';
      },
      firstName: value => (value ? null : 'First name is required'),
      agreeToTermsAndConditions: value =>
        value
          ? null
          : 'You must agree to the Terms of Service & Privacy Policy',
    },
  });

  const resetAllForms = useCallback(() => {
    emailForm.reset();
    passwordForm.reset();
    newUserForm.reset();
  }, [emailForm, newUserForm, passwordForm]);

  function canSubmit() {
    if (typedPath === '/signup/sso' || typedPath === '/signup/forgot') {
      return emailForm.isValid();
    }

    if (typedPath === '/signup/new_user') {
      return (
        emailForm.isValid() && passwordForm.isValid() && newUserForm.isValid()
      );
    }

    if (typedPath === '/signup/email') {
      return emailForm.isValid() && passwordForm.isValid();
    }
    return false;
  }

  // this gets the email and password from the email/password forms on submit:
  // convenince function to cleanup the form submit logic
  const handleEmailPasswordSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      let submittedEmail = '';
      let submittedPassword = '';

      emailForm.onSubmit(values => {
        submittedEmail = values.email;
      })(e);

      passwordForm.onSubmit(values => {
        submittedPassword = values.password;
      })(e);

      return { email: submittedEmail, password: submittedPassword };
    },
    [emailForm, passwordForm]
  );

  const handleNewUserSubmit = useCallback(
    ({
      e,
      email,
      password,
    }: {
      e: React.FormEvent<HTMLFormElement>;
      email: string;
      password: string;
    }) => {
      newUserForm.onSubmit(values => {
        loadingTask({
          task: () =>
            registerNewUser({
              data: {
                ...values,
                password,
                email,
              },
              search,
            })
              .then(data => {
                notifications.success({ message: data.message });
                resetAllForms();
                typedNavigate('/signup');
              })
              .catch(e => {
                notifications.error({ message: e.message });
              }),
          loadingId: 'new-user',
        });
      })(e);
    },
    [newUserForm, loadingTask, search, resetAllForms, typedNavigate]
  );

  const handleSignInWithEmail = useCallback(
    ({ email, password }: { email: string; password: string }) => {
      loadingTask({
        task: () =>
          signInWithEmail({
            email,
            password,
            search,
          })
            .then(({ location }) => {
              loginCallback({ loginRedirectLocation: location });
            })
            .catch(e => {
              if (e.message.includes('Invalid Credentials')) {
                passwordForm.setErrors({
                  password: 'Invalid email or password',
                });
              }
              notifications.error({ message: e.message });
            }),
        loadingId: 'email',
      });
    },
    [loadingTask, search, loginCallback, passwordForm]
  );

  const handleSignInWithSSO = useCallback(
    ({ email }: { email: string }) => {
      loadingTask({
        task: () =>
          signInWithSSO({
            email,
            search,
          })
            .then(({ auth_url }) => {
              window.location.replace(auth_url);
              return unReslovedPromise;
            })
            .catch(e => {
              notifications.error({ message: e.message });
            }),
        loadingId: 'sso',
      });
    },
    [loadingTask, search]
  );

  const handleResetPassword = useCallback(
    ({ email }: { email: string }) => {
      loadingTask({
        task: () =>
          resetPassword({
            email,
          })
            .then(({ message }) => {
              notifications.success({ message });

              resetAllForms();

              navigate({
                pathname: paths.signup(),
                search: getRedir(search),
              });
            })
            .catch(e => {
              notifications.error({ message: e.message });
            }),
        loadingId: 'reset-password',
      });
    },
    [loadingTask, resetAllForms, navigate, search]
  );

  const handleFormSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      const { email, password } = handleEmailPasswordSubmit(e);

      if (typedPath === '/signup/new_user') {
        handleNewUserSubmit({ e, email, password });
      }

      if (typedPath === '/signup/email') {
        handleSignInWithEmail({ email, password });
      }

      if (typedPath === '/signup/sso') {
        // handle sso
        handleSignInWithSSO({ email });
      }

      if (typedPath === '/signup/forgot') {
        // handle forgot password
        handleResetPassword({ email });
      }
    },
    [
      handleEmailPasswordSubmit,
      typedPath,
      handleNewUserSubmit,
      handleSignInWithEmail,
      handleSignInWithSSO,
      handleResetPassword,
    ]
  );

  const handleBackButton = useCallback(() => {
    if (
      typedPath === '/signup/email' ||
      typedPath === '/signup/sso' ||
      typedPath === '/signup/new_user'
    ) {
      typedNavigate('/signup');
      resetAllForms();
    } else if (typedPath === '/signup/forgot') {
      typedNavigate('/signup/email');
    }
  }, [resetAllForms, typedNavigate, typedPath]);

  // this moves the user to the next step if they enter a valid email while on the initial route
  emailForm.watch('email', ({ value }) => {
    if (typedPath === '/signup') {
      if (isEmail(value)) {
        typedNavigate('/signup/email');
      }
    }
  });

  const isNarrow = useMatches({
    base: true,
    sm: false,
  });

  const signInWithGithub = useCallback(() => {
    loadingTask({ task: () => unReslovedPromise, loadingId: 'github' });
    window.location.assign(
      oauthUrl({
        authUrl: githubOAuthUrl,
        search,
      })
    );
  }, [githubOAuthUrl, loadingTask, search]);

  const signInWithGoogle = useCallback(() => {
    loadingTask({ task: () => unReslovedPromise, loadingId: 'google' });
    window.location.assign(
      oauthUrl({
        authUrl: googleOAuthUrl,
        search,
      })
    );
  }, [googleOAuthUrl, loadingTask, search]);

  const hasNotifiedOfConfirmedEmail = useRef(false);
  useEffect(() => {
    if (
      new URLSearchParams(search).get('is_confirmed') === 'true' &&
      !hasNotifiedOfConfirmedEmail.current
    ) {
      notifications.success({
        message: 'Email confirmed successfully!',
      });
      hasNotifiedOfConfirmedEmail.current = true;
    }
  }, []);

  return (
    <AppShell
      withBorder={false}
      header={{ height: 64 }}
      navbar={{ width: 0, breakpoint: 'sm' }}
    >
      <AppShell.Header bg={'var(--mantine-color-background-3)'}>
        <LoginTopBar h={'100%'} w={'100%'} px={'lg'} />
      </AppShell.Header>

      <AppShell.Main bg={'var(--mantine-color-background-3)'} display={'flex'}>
        <Stack py={'xl'} flex={1} align="center" justify="center">
          <LoginWidgetContainer
            aboveMobileContent={
              <LoginTopBar
                hideLogo={typedPath !== '/signup'}
                p={'lg'}
                mb={'xl'}
              />
            }
            fullScreen={isNarrow}
          >
            <Box p={isNarrow ? '1.5rem' : '.5rem'}>
              <Box className="absolute left-0 top-0" m={'lg'}>
                <AnimateInAndOut
                  skipFirstRender
                  delay
                  visible={typedPath !== '/signup'}
                >
                  <Button
                    disabled={isLoading}
                    style={{ alignSelf: 'flex-start' }}
                    onClick={handleBackButton}
                    variant="subtle"
                    leftSection={<PiCaretLeft />}
                  >
                    Back
                  </Button>
                </AnimateInAndOut>
              </Box>
              <Stack gap={'sm'}>
                <Title mb={'lg'} style={{ alignSelf: 'center' }} order={2}>
                  {PageTitle[typedPath]}
                </Title>

                <AnimateInAndOut
                  visible={typedPath === '/signup'}
                  skipFirstRender
                >
                  <Stack>
                    {ssoError && (
                      <Alert color="red" icon={<PiWarning size={ICON_SIZE} />}>
                        {isSamlAuthSupported ? (
                          <>
                            OAuth2 login failed because your organization likely
                            has{' '}
                            <Anchor
                              fz={'inherit'}
                              component={Link}
                              to={{
                                pathname: paths.v3.login.sso(),
                                search,
                              }}
                            >
                              SSO/SAML
                            </Anchor>{' '}
                            enabled. For any other issues,{' '}
                            <Anchor
                              fz={'inherit'}
                              target="_blank"
                              href={LINKS.contact}
                            >
                              contact us
                            </Anchor>{' '}
                            for further assistance.
                          </>
                        ) : (
                          <>
                            OAuth2 login failed. Please{' '}
                            <Anchor
                              fz={'inherit'}
                              target="_blank"
                              href={LINKS.contact}
                            >
                              contact us
                            </Anchor>{' '}
                            further assistance.
                          </>
                        )}
                      </Alert>
                    )}
                    {isAuthModeSuppported('google') && (
                      <AuthButton
                        loading={isIdLoading('google')}
                        leftSection={<FcGoogle size={ICON_SIZE} />}
                        onClick={signInWithGoogle}
                      >
                        Continue with Google
                      </AuthButton>
                    )}
                    {isAuthModeSuppported('github') && (
                      <AuthButton
                        loading={isIdLoading('github')}
                        leftSection={<VscGithubInverted size={ICON_SIZE} />}
                        onClick={signInWithGithub}
                      >
                        Continue with Github
                      </AuthButton>
                    )}
                    {isSamlAuthSupported && (
                      <AuthButton
                        disabled={isLoading}
                        variant="default"
                        leftSection={<PiCity size={ICON_SIZE} />}
                        onClick={() => {
                          typedNavigate('/signup/sso');
                        }}
                      >
                        Continue with SSO
                      </AuthButton>
                    )}
                    <OrDivider />
                  </Stack>
                </AnimateInAndOut>

                <form onSubmit={handleFormSubmit}>
                  <Stack>
                    <AnimateInAndOut
                      skipFirstRender
                      visible={isNewUserOrEmail}
                      delay
                    >
                      <SegmentedControl
                        disabled={isLoading}
                        w={'100%'}
                        data={['New User', 'Existing User']}
                        value={
                          typedPath === '/signup/new_user'
                            ? 'New User'
                            : 'Existing User'
                        }
                        onChange={value => {
                          if (value === 'New User') {
                            typedNavigate('/signup/new_user');
                          } else {
                            typedNavigate('/signup/email');
                          }
                        }}
                      />
                    </AnimateInAndOut>

                    <AnimateInAndOut
                      skipFirstRender
                      visible={typedPath === '/signup/new_user'}
                    >
                      <Text fw={500}>Login Info:</Text>
                    </AnimateInAndOut>

                    {/* shared email input for all workflow */}
                    <StyledTextInput
                      placeholder={
                        typedPath === '/signup/new_user' ||
                        typedPath === '/signup/forgot'
                          ? 'Email'
                          : 'Continue with Email'
                      }
                      leftSection={<PiEnvelopeSimple size={ICON_SIZE - 2} />}
                      key={emailForm.key('email')}
                      autoComplete="email"
                      disabled={isLoading}
                      {...emailForm.getInputProps('email')}
                    />

                    <AnimateInAndOut skipFirstRender visible={isNewUserOrEmail}>
                      <Stack gap={8}>
                        <PasswordInput
                          autoCompleteType={
                            typedPath === '/signup/new_user'
                              ? 'new-password'
                              : 'current-password'
                          }
                          disabled={isLoading}
                          showStrengthPopover={typedPath === '/signup/new_user'}
                          formInputProps={passwordForm.getInputProps(
                            'password'
                          )}
                        />
                        {typedPath === '/signup/email' && (
                          <motion.div
                            initial={{ opacity: 1 }}
                            transition={{ duration: 0.2 }}
                            animate={
                              isLoading ? { opacity: 0.5 } : { opacity: 1 }
                            }
                            style={{ alignSelf: 'flex-end' }}
                          >
                            <Anchor
                              style={{
                                pointerEvents: isLoading ? 'none' : 'auto',
                              }}
                              component={Link}
                              to={{
                                pathname:
                                  '/signup/forgot' satisfies UnProtectedRoute,
                                search,
                              }}
                              mx={'lg'}
                              fz={'sm'}
                              fw={500}
                            >
                              Forgot Password?
                            </Anchor>
                          </motion.div>
                        )}
                      </Stack>

                      <AnimateInAndOut
                        skipFirstRender
                        visible={typedPath === '/signup/new_user'}
                      >
                        <PasswordInput
                          autoCompleteType="new-password"
                          disabled={isLoading}
                          placeholder="Confirm Password"
                          formInputProps={newUserForm.getInputProps(
                            'confirmPassword'
                          )}
                          icon={
                            passwordForm.isValid() &&
                            passwordForm.values.password ===
                              newUserForm.values.confirmPassword ? (
                              <ThemeIcon variant="subtle" color="teal">
                                <PiChecks size={ICON_SIZE} />
                              </ThemeIcon>
                            ) : (
                              <ThemeIcon
                                variant="subtle"
                                color={
                                  newUserForm.values.confirmPassword
                                    ? 'red'
                                    : 'gray'
                                }
                              >
                                <PiXCircle size={ICON_SIZE} />
                              </ThemeIcon>
                            )
                          }
                        />
                        <Text fw={500}>Profile Info:</Text>
                        <StyledTextInput
                          disabled={isLoading}
                          placeholder="First Name"
                          autoComplete="given-name"
                          leftSection={<PiUser />}
                          {...newUserForm.getInputProps('firstName')}
                        />
                        <StyledTextInput
                          disabled={isLoading}
                          leftSection={<PiUser />}
                          autoComplete="family-name"
                          placeholder="Last Name (optional)"
                          {...newUserForm.getInputProps('lastName')}
                        />
                        <StyledTextInput
                          disabled={isLoading}
                          leftSection={<PiCity />}
                          placeholder="Organization (optional)"
                          {...newUserForm.getInputProps('organization')}
                        />
                        <Checkbox
                          disabled={isLoading}
                          radius={'sm'}
                          my={'xs'}
                          label={
                            <Text>
                              By signing up, I agree to the{' '}
                              <Anchor
                                href={LINKS.termsOfService}
                                target="_blank"
                                inherit
                              >
                                Terms of Service
                              </Anchor>{' '}
                              &{' '}
                              <Anchor
                                href={LINKS.privacyPolicy}
                                target="_blank"
                                inherit
                              >
                                Privacy Policy.
                              </Anchor>
                            </Text>
                          }
                          {...newUserForm.getInputProps(
                            'agreeToTermsAndConditions'
                          )}
                        />
                      </AnimateInAndOut>
                    </AnimateInAndOut>

                    <AnimateInAndOut
                      skipFirstRender
                      visible={ShouldShowSubmitButton[typedPath]}
                    >
                      {/* Submit Button: */}
                      <AuthButton
                        loading={isLoading}
                        type="submit"
                        c={undefined}
                        variant="primary"
                        disabled={!canSubmit()}
                        rightSection={<PiCaretRight />}
                      >
                        {SubmitButtonTitle[typedPath]}
                      </AuthButton>
                    </AnimateInAndOut>
                  </Stack>
                </form>
              </Stack>

              {!validPathName && (
                <Alert
                  mt={'md'}
                  color="red"
                  icon={<PiWarning size={ICON_SIZE} />}
                >
                  <Text fz={'inherit'}>
                    No <code>AUTH_METHODS</code> detected in your configuration.
                  </Text>
                  <Text fz={'inherit'}>Please contact you administrator.</Text>
                </Alert>
              )}
            </Box>
          </LoginWidgetContainer>
        </Stack>
      </AppShell.Main>
    </AppShell>
  );
};

export function NewLoginPage(props: LandingPageProps) {
  return (
    <MantineProvider>
      <NewLoginPageUI {...props} />
    </MantineProvider>
  );
}
