import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const COOKIE_NAME = 'oauth_redirect';
const COOKIE_MAX_AGE = 300; // 5 minutes

interface UseRedirectUrlPersistenceOptions {
  cookieName?: string;
  maxAge?: number;
}

const isValidUrl = (url: string): boolean => {
  try {
    return Boolean(new URL(url, window.location.origin));
  } catch {
    return false;
  }
};

const getCookie = (name: string): string | null => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return decodeURIComponent(parts.pop()?.split(';').shift() || '');
  }
  return null;
};

const setCookie = (name: string, value: string, maxAge: number): void => {
  document.cookie = `${name}=${encodeURIComponent(value)}; max-age=${maxAge}; path=/; SameSite=Strict`;
};

export const useRedirectUrlPersistence = (
  options?: UseRedirectUrlPersistenceOptions
) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const cookieName = options?.cookieName || COOKIE_NAME;
  const maxAge = options?.maxAge || COOKIE_MAX_AGE;

  useEffect(() => {
    const currentRedirectUrl = searchParams.get('redirect_url');
    const storedRedirectUrl = getCookie(cookieName);

    // Case 1: Handle OAuth2 error scenario
    if (currentRedirectUrl?.includes('oauth2-error')) {
      if (storedRedirectUrl && isValidUrl(storedRedirectUrl)) {
        // Swap redirect_url and error_url
        searchParams.set('error_url', currentRedirectUrl);
        searchParams.set('redirect_url', storedRedirectUrl);
        setSearchParams(searchParams);
        return;
      }
    }

    // Case 2: Handle valid redirect_url scenario
    if (currentRedirectUrl && isValidUrl(currentRedirectUrl)) {
      if (currentRedirectUrl !== storedRedirectUrl) {
        setCookie(cookieName, currentRedirectUrl, maxAge);
      }
    }
  }, []); // Run only once on mount

  return null;
};

// Export helper functions if needed elsewhere
export const redirectUrlUtils = {
  isValidUrl,
  getCookie,
  setCookie,
};
